<template>
  <div class="h-100 d-flex flex-column">
    <div class="h-100 d-flex">
      <div class="login-card-wrapper mx-auto">
        <div class="top-20">
          <app-form
            v-bind="displayMode"
            :error-message="errorMessage"
            :success-message="successMessage"
            :loading="loading"
            input-set-id="login"
          >
            <template v-slot:logo>
              <span
                class="mdi mdi-check-circle-outline text-primary text-center mb-4"
                style="font-size: 5rem"
              />
            </template>
          </app-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '@/store/auth/action.types'
import { SET_LOGGED_IN_USER } from '@/store/auth/mutation.types'
import AppForm from '@/components/AppForm'
import { MISSING_COGNITO_GROUP_MEMBERSHIP } from '@/application.constants'

export default {
  components: {
    AppForm
  },

  data () {
    return {
      displayModes: undefined,
      displayMode: undefined,
      newPasswordUser: undefined,

      errorMessage: '',
      successMessage: '',

      loading: false
    }
  },

  watch: {
    displayMode (newValue, oldValue) {
      this.errorMessage = ''

      if (newValue !== this.displayModes.newPassword) {
        this.newPasswordUser = undefined
      }

      // reset passwords and get username from previous display
      const userName = this.getInputValue('username', oldValue).trim()
      for (const input of newValue.inputs) {
        if (input.key === 'username') {
          input.value = userName
        } else if (input.key.match(/password/i)) {
          input.value = ''
        }
      }
    }
  },

  created () {
    this.displayModes = {

      login: {
        title: this.$t('login.title'),
        inputs: [
          {
            key: 'username',
            label: this.$t('login.username'),
            required: true,
            value: '',
            displayed: true,
            autofocus: true
          },
          {
            key: 'password',
            inputType: 'password',
            label: this.$t('login.password'),
            required: true,
            value: '',
            displayed: true
          }
        ],
        buttonText: this.$t('login.buttonLabel'),
        onSubmit: this.initiateLogin,
        link: {
          text: this.$t('login.forgotPasswordQuestion'),
          onClick: () => {
            this.errorMessage = ''
            this.successMessage = ''
            this.displayMode = this.displayModes.initiateForgotPassword
          }
        }
      },

      newPassword: {
        title: this.$t('login.changePassword'),
        inputs: [
          {
            key: 'username',
            label: this.$t('login.username'),
            required: true,
            disabled: true,
            value: '',
            displayed: true
          },
          {
            key: 'password',
            inputType: 'password',
            label: this.$t('login.password'),
            required: true,
            value: '',
            displayed: true,
            autofocus: true,
            confirmPassword: true,
            confirmPasswordLabel: this.$t('login.confirmPassword')
          }
        ],
        buttonText: this.$t('login.changePassword'),
        onSubmit: this.completeNewPassword,
        link: {
          text: this.$t('login.backToLogin'),
          onClick: () => {
            this.displayMode = this.displayModes.login
          }
        }
      },

      initiateForgotPassword: {
        title: this.$t('login.forgotPassword'),
        inputs: [
          {
            key: 'username',
            label: this.$t('login.username'),
            required: true,
            value: '',
            displayed: true,
            autofocus: true
          }
        ],
        buttonText: this.$t('login.send'),
        onSubmit: this.initiateForgotPassword,
        link: {
          text: this.$t('login.backToLogin'),
          onClick: () => {
            this.displayMode = this.displayModes.login
          }
        }
      },

      submitForgotPassword: {
        title: this.$t('login.resetPassword'),
        inputs: [
          {
            key: 'username',
            label: this.$t('login.username'),
            required: true,
            disabled: true,
            value: '',
            displayed: true
          },
          {
            key: 'verificationCode',
            label: this.$t('login.verificationCode'),
            required: true,
            value: '',
            displayed: true,
            autofocus: true
          },
          {
            key: 'password',
            inputType: 'password',
            label: this.$t('login.password'),
            required: true,
            value: '',
            displayed: true,
            confirmPassword: true,
            confirmPasswordLabel: this.$t('login.confirmPassword')
          }
        ],
        buttonText: this.$t('login.resetPassword'),
        onSubmit: this.submitForgotPassword,
        link: {
          text: this.$t('login.backToLogin'),
          onClick: () => {
            this.displayMode = this.displayModes.login
          }
        }
      }
    }

    this.displayMode = this.displayModes.login
  },

  methods: {
    getInputValue (inputName, displayMode = this.displayMode) {
      const input = displayMode.inputs.find(i => i.key === inputName)
      return input && input.value
    },

    async initiateLogin () {
      this.loading = true

      try {
        const username = this.getInputValue('username').trim()
        const password = this.getInputValue('password')

        const user = await this.$store.dispatch(actionTypes.LOGIN, { username, password })

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.displayMode = this.displayModes.newPassword
          this.newPasswordUser = user
          this.loading = false
          return
        }

        this.completeLogin(user)
      } catch (error) {
        if (error.code === 'PasswordResetRequiredException') {
          this.displayMode = this.displayModes.submitForgotPassword
        } else if (['NotAuthorizedException', 'UserNotFoundException'].includes(error.code)) {
          this.errorMessage = this.$t('login.incorrectUsernameOrPassword')
        } else {
          console.error(error)
          if ('message' in error) {
            if (error.message === MISSING_COGNITO_GROUP_MEMBERSHIP) {
              this.errorMessage = this.$t('login.missingCognitoGroupMembership')
            } else {
              this.errorMessage = this.$t('login.invalidUsername')
            }
          } else {
            this.errorMessage = error
          }
        }

        // Disabling loader from catch instead of finally to prevent a flash at successful login
        this.loading = false
      }
    },

    async completeNewPassword () {
      this.loading = true

      try {
        const newPassword = this.getInputValue('password')

        const user = await this.$store.dispatch(actionTypes.COMPLETE_NEW_PASSWORD, { user: this.newPasswordUser, newPassword })
        this.completeLogin(user)
      } catch (error) {
        console.error(error)
        if ('message' in error) {
          if (error.message === MISSING_COGNITO_GROUP_MEMBERSHIP) {
            this.displayMode = this.displayModes.login
            await this.$nextTick()
            this.errorMessage = this.$t('login.missingCognitoGroupMembership')
          } else {
            this.errorMessage = this.$t(error.message)
          }
        } else {
          this.errorMessage = error
        }

        this.resetInputs(['password'])
        this.loading = false
      }
    },

    async completeLogin (user) {
      this.$store.commit(SET_LOGGED_IN_USER, user)
    },

    async initiateForgotPassword () {
      this.loading = true
      this.errorMessage = ''
      this.successMessage = ''

      try {
        const username = this.getInputValue('username').trim()

        await this.$store.dispatch(actionTypes.FORGOT_PASSWORD, { username })
        this.displayMode = this.displayModes.submitForgotPassword
      } catch (error) {
        console.error(error)
        if (error.code === 'UserNotFoundException') {
          this.errorMessage = this.$t('login.forgotPasswordUsernameNotFound')
        } else if ('message' in error) {
          this.errorMessage = this.$t(error.message)
        } else {
          this.errorMessage = error
        }
      } finally {
        this.loading = false
      }
    },

    async submitForgotPassword () {
      this.loading = true

      try {
        const username = this.getInputValue('username').trim()
        const code = this.getInputValue('verificationCode').trim()
        const newPassword = this.getInputValue('password')

        await this.$store.dispatch(actionTypes.FORGOT_PASSWORD_SUBMIT, { username, code, newPassword })

        this.displayMode = this.displayModes.login
        this.successMessage = this.$t('login.passwordResetSuccessfully')
      } catch (error) {
        console.error(error)
        if ('message' in error) {
          this.errorMessage = this.$t(error.message)
        } else {
          this.errorMessage = error
        }
      } finally {
        this.resetInputs(['password'])
        this.loading = false
      }
    },

    resetInputs (inputKeys) {
      this.displayMode.inputs
        .filter(input => inputKeys.includes(input.key))
        .forEach(input => { input.value = '' })
    }
  }
}
</script>

<style scoped>
.login-card-wrapper {
  max-width: 25rem;
  min-width: 25rem;
}

.top-20 {
  position: relative;
  top: 20%;
}
</style>
