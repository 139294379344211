<template>
  <div>
    <b-form-group
      :label-cols="showLabels ? labelSize : 0"
      :label-for="id"
    >
      <template
        v-if="showLabels"
        slot="label"
      >
        <span :class="{ 'property-required': required }">{{ label }}</span>
      </template>

      <b-input-group class="d-flex flex-nowrap">
        <b-form-input
          :id="id"
          :ref="id"
          v-model="internalValue"
          :placeholder="internalPlaceholder"
          :disabled="disabled"
          :state="isValidPassword ? null : isValidPassword"
          type="password"
          :autofocus="autofocus"
          @keyup.enter="allowSubmit && onSubmit()"
          @focus="passwordWasFocused = true"
          @blur="passwordWasBlurred = true"
        />
      </b-input-group>
    </b-form-group>

    <template v-if="confirmPassword">
      <transition name="fade">
        <div v-if="passwordWasFocused && !allRuleFulfilled">
          <ul class="mb-0 pb-3">
            <transition-group name="fade">
              <template v-for="rule of ruleStatuses">
                <li
                  v-if="!rule.status"
                  :key="rule.message"
                  :class="{
                    'text-danger': passwordWasBlurred && !rule.status
                  }"
                >
                  {{ $t(rule.message) }}
                </li>
              </template>
            </transition-group>
          </ul>
        </div>
      </transition>

      <b-form-group
        :label-cols="showLabels ? labelSize : 0"
        :label-for="confirmId"
      >
        <template
          v-if="showLabels"
          slot="label"
        >
          <span :class="{ 'property-required': required }">{{ confirmPasswordLabel }}</span>
        </template>

        <b-input-group class="d-flex flex-nowrap">
          <b-form-input
            :id="confirmId"
            :ref="confirmId"
            v-model="confirmValue"
            :placeholder="internalConfirmPlaceholder"
            :disabled="disabled"
            :state="isValidConfirmPassword ? null : isValidConfirmPassword"
            type="password"
            @keyup.enter="allowSubmit && onSubmit()"
            @blur="() => {
              if (value) {
                confirmPasswordWasBlurred = true
              }
            }"
          />
        </b-input-group>
      </b-form-group>

      <transition name="fade">
        <div
          v-if="value !== '' && confirmValue !== '' && confirmValue !== value"
          :class="{
            'text-danger': confirmPasswordWasBlurred
          }"
        >
          <ul class="mb-0 pb-3">
            <li>
              {{ $t('login.passwordsMustMatch') }}
            </li>
          </ul>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import { authentication } from '@/application.config'

export default {
  props: {
    allowSubmit: Boolean,
    autofocus: Boolean,
    disabled: Boolean,
    id: {
      type: String,
      required: true
    },
    invalidMessage: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    labelSize: {
      type: Number,
      default: 3
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: undefined
    },
    required: Boolean,
    showLabels: Boolean,
    validate: Boolean,
    value: {
      type: String,
      default: ''
    },
    confirmPassword: Boolean,
    confirmPasswordPlaceholder: {
      type: String,
      default: undefined
    },
    confirmPasswordLabel: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      confirmValue: '',
      passwordWasFocused: false,
      passwordWasBlurred: false,
      confirmPasswordWasBlurred: false,
      isValidPassword: true,
      isValidConfirmPassword: null
    }
  },

  computed: {
    allRuleFulfilled () {
      return this.ruleStatuses.every(rule => rule.status)
    },

    confirmId () {
      return [this.id, 'confirm'].join('-')
    },

    internalValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('update:value', value)
      }
    },

    internalPlaceholder () {
      return this.placeholder || this.label
    },

    internalConfirmPlaceholder () {
      return this.confirmPasswordPlaceholder || this.confirmPasswordLabel
    },

    isValid () {
      return this.isValidPassword && (!this.confirmPassword || this.isValidConfirmPassword)
    },

    ruleStatuses () {
      return authentication.passwordRules.map(rule => ({
        message: rule.message,
        status: rule.regex.test(this.value)
      }))
    }
  },

  watch: {
    value (currentValue) {
      this.validatePassword({ currentValue })
      this.validateConfirmPassword()
    },

    confirmValue (currentValue) {
      this.validateConfirmPassword({ currentValue })
    },

    confirmPasswordWasBlurred () {
      this.validateConfirmPassword()
    },

    passwordWasBlurred () {
      this.validatePassword()
    },

    validate (performValidation) {
      if (performValidation) {
        this.validatePassword({ forced: true })

        if (this.confirmPassword) {
          this.validateConfirmPassword({ forced: true })
        }
      } else {
        this.isValidPassword = null
      }
    }
  },

  methods: {
    setAutofocus () {
      this.$refs[this.id].focus()
    },

    validatePassword ({ currentValue, forced } = {}) {
      if (currentValue === undefined) {
        currentValue = this.value
      }

      this.isValidPassword = this.disabled || !this.required || currentValue !== ''

      if (this.confirmPassword) {
        if (forced || this.passwordWasBlurred) {
          this.isValidPassword = this.allRuleFulfilled
        }
      }
    },

    validateConfirmPassword ({ currentValue, forced } = {}) {
      if (currentValue === undefined) {
        currentValue = this.confirmValue
      }

      if (forced || this.confirmPasswordWasBlurred) {
        this.isValidConfirmPassword = this.value === currentValue && this.value !== ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables';

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s, max-height 1s;
  max-height: 100px;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  max-height: 0px;
  color: $success !important;
}
</style>
