<template>
  <div>
    <template v-for="input in inputs">
      <template v-if="input.displayed">
        <app-password-input
          v-if="input.inputType === inputTypes.password"
          :id="[inputSetId, 'input', input.key].join('-')"
          :ref="input.key"
          :key="input.key"
          :show-labels="showLabels"
          :label-size="labelSize"
          v-bind="input"
          :allow-submit="allowSubmit"
          :on-submit="onSubmit"
          :validate="validate"
          @update:value="input.value = $event"
        />

        <app-input
          v-else
          :id="[inputSetId, 'input', input.key].join('-')"
          :ref="input.key"
          :key="input.key"
          :show-labels="showLabels"
          :label-size="labelSize"
          v-bind="input"
          :allow-submit="allowSubmit"
          :on-submit="onSubmit"
          :validate="validate"
          @update:value="input.value = $event"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppInput from './AppInput'
import AppPasswordInput from './AppPasswordInput'

export default {
  components: {
    AppInput,
    AppPasswordInput
  },

  props: {
    inputSetId: {
      type: String,
      required: true
    },
    inputs: {
      type: Array,
      required: true
    },
    allowSubmit: {
      type: Boolean
    },
    labelSize: {
      type: Number,
      default: 3
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    showLabels: Boolean,
    validate: Boolean
  },

  data () {
    return {
      children: []
    }
  },

  computed: {
    isValid () {
      return this.children.every(child => child.isValid)
    },

    ...mapGetters(['inputTypes'])
  },

  watch: {
    inputs (newValue) {
      const autofocusInput = newValue.find(input => input.autofocus)
      if (autofocusInput) {
        this.$nextTick(() => this.$refs[autofocusInput.key][0].setAutofocus())
      }
    },

    isValid (newValue) {
      this.$emit('update:validation', newValue)
    }
  },

  created () {
    this.children = this.$children
  }
}
</script>
