<template>
  <b-form-group
    :label-cols="showLabels ? labelSize : 0"
    :label-for="id"
    :description="description"
  >
    <template
      v-if="showLabels"
      slot="label"
    >
      <span :class="{ 'property-required': required }">{{ label }}</span>
    </template>
    <b-input-group class="d-flex flex-nowrap">
      <v-select
        v-if="inputType === inputTypes.select"
        :id="id"
        v-model="internalValue"
        class="w-100"
        :class="{ 'is-invalid': isValidInternal === false }"
        :title="value"
        :disabled="disabled"
        :options="select.options"
        :placeholder="internalPlaceholder"
        :label="select.label"
        :loading="isLoading"
        :reduce="item => item[select.value]"
        :taggable="select.taggable"
        :push-tags="select.taggable"
        :create-option="item => ({ [select.value]: item })"
        :no-drop="disabled"
        :clearable="!disabled"
      >
        <template #spinner="{ loading }">
          <b-spinner
            v-if="loading"
            small
            class="vue-select-spinner"
          />
        </template>
      </v-select>

      <b-form-datepicker
        v-else-if="inputType === inputTypes.date"
        :id="id"
        v-model="internalDateValue"
        :placeholder="internalPlaceholder"
        :disabled="disabled"
        today-button
        reset-button
        close-button
        :locale="config.application.dateLocal"
        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
        today-button-variant="primary"
        reset-button-variant="danger"
        close-button-variant="outline-primary"
        right
        :state="isValidInternal"
      />

      <template
        v-else-if="inputType === inputTypes.dateTime"
      >
        <b-form-datepicker
          :id="id"
          v-model="internalDateValue"
          :placeholder="internalPlaceholder"
          :disabled="disabled"
          :state="isValidInternal"
          today-button
          reset-button
          close-button
          :locale="config.application.dateLocal"
          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          today-button-variant="primary"
          reset-button-variant="danger"
          close-button-variant="outline-primary"
          right
        />

        <b-form-timepicker
          v-model="internalTimeValue"
          :disabled="disabled"
          :state="isValidInternal"
          :locale="config.application.timeLocal"
        />
      </template>

      <template v-else-if="inputType === inputTypes.boolean">
        <span
          :id="id"
          class="mdi mdi-1_75rem w-100 h-100 mt-1"
          :class="internalValue ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'"
          @click="if (!disabled) internalValue = !internalValue"
        />
      </template>

      <b-form-input
        v-else
        :id="id"
        :ref="id"
        v-model="internalValue"
        :placeholder="internalPlaceholder"
        :disabled="disabled"
        :state="isValidInternal"
        :type="inputType"
        :autofocus="autofocus"
        @keyup.enter="allowSubmit && onSubmit()"
      />

      <b-input-group-append v-if="showOverride">
        <b-button
          variant="outline-primary"
          @click="onOverride()"
        >
          <span
            class="mdi mdi-pencil mdi-1_25rem"
            :title="overrideTitle"
          />
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <b-form-invalid-feedback
      :state="isValidInternal"
    >
      {{ invalidMessage }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    allowSubmit: Boolean,
    autofocus: Boolean,
    description: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    id: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      default: 'text'
    },
    isLoading: {
      type: Boolean
    },
    invalidMessage: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    labelSize: {
      type: Number,
      default: 3
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    onOverride: {
      type: Function,
      default: () => {}
    },
    overrideTitle: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: undefined
    },
    required: Boolean,
    select: {
      type: Object,
      default: () => ({
        options: [],
        value: 'value',
        label: 'label'
      })
    },
    showLabels: Boolean,
    showOverride: Boolean,
    validate: Boolean,
    validator: {
      type: Function,
      default: undefined
    },
    value: {
      type: [String, Date, Number, Boolean],
      default: ''
    }
  },

  data () {
    return {
      isValid: true
    }
  },

  computed: {
    internalValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('update:value', value)
      }
    },

    internalDateValue: {
      get () {
        if (this.inputTypes.date === this.inputType || this.inputTypes.dateTime === this.inputType) {
          return this.value ? this.value.substring(0, this.value.indexOf('T')) : ''
        }
        return ''
      },
      set (value) {
        const timeValue = this.inputTypes.dateTime === this.inputType ? this.internalTimeValue : '00:00:00'
        this.$emit('update:value', value ? value + 'T' + timeValue + '.000Z' : undefined)
      }
    },

    internalTimeValue: {
      get () {
        if (this.inputTypes.dateTime === this.inputType) {
          return this.value ? this.value.substring(this.value.indexOf('T') + 1, this.value.indexOf('.')) : ''
        }
        return ''
      },
      set (value) {
        this.$emit('update:value', this.internalDateValue + 'T' + value + '.000Z')
      }
    },

    internalPlaceholder () {
      return this.placeholder || this.label
    },

    isValidInternal () {
      return this.isValid ? null : false
    },

    ...mapGetters(['config', 'inputTypes'])
  },

  watch: {
    value (newValue) {
      this.validateInput(newValue)
    },

    validate (performValidation) {
      if (performValidation) {
        this.validateInput()
      } else {
        this.isValid = true
      }
    }
  },

  mounted () {
    if (this.validate) {
      this.validateInput()
    }
  },

  methods: {
    setAutofocus () {
      this.$refs[this.id].focus()
    },

    validateInput (currentValue) {
      if (currentValue === undefined) {
        currentValue = this.value
      }

      if (this.validator) {
        this.isValid = this.validator(currentValue)
        return
      }

      this.isValid = this.disabled || !this.required || (currentValue !== null && currentValue !== undefined && currentValue !== '')
    }
  }
}
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables';

.bg-disabled {
  background-color: $input-disabled-bg !important;
}

.is-invalid {
  ::v-deep .vs__dropdown-toggle {
    border-color: $red !important;
  }
}

::v-deep .vs__dropdown-toggle {
  border-radius: 0.25rem !important;
}

::v-deep .vs__search {
  line-height: 1.5;
}

::v-deep .vs__selected-options {
  text-overflow: ellipsis !important;
  overflow-x: hidden;
  white-space: no-wrap !important;
}

</style>
