<template>
  <b-card
    class="shadow"
  >
    <div class="m-4">
      <slot name="logo" />
      <b-card-title class="text-primary text-center mb-3">
        {{ title }}
      </b-card-title>
      <div
        v-if="loading"
        class="d-flex align-items-center justify-content-center"
      >
        <b-spinner class="large-spinner" />
      </div>

      <div v-else>
        <!-- Inputs -->
        <app-input-set
          :input-set-id="inputSetId"
          :inputs="inputs"
          allow-submit
          :on-submit="submit"
          :validate="validate"
          @update:validation="areInputsValid = $event"
        />

        <!-- Button -->
        <b-form-group>
          <b-button
            block
            variant="primary"
            @click="submit"
          >
            {{ buttonText }}
          </b-button>
        </b-form-group>

        <b-form-group>
          <!-- Messages: optional -->
          <span
            v-if="errorMessage"
            class="text-form-message-error"
          >{{ errorMessage }}</span>
          <span
            v-else-if="successMessage"
            class="text-form-message-success"
          >{{ successMessage }}</span>

          <!-- Link: optional -->
          <b-link
            v-if="link"
            href="#"
            class="d-flex justify-content-center"
            @click="link.onClick"
          >
            {{ link.text }}
          </b-link>
        </b-form-group>
      </div>
    </div>
  </b-card>
</template>

<script>
import AppInputSet from './AppInputSet'

export default {
  components: {
    AppInputSet
  },

  props: {
    title: {
      type: String,
      required: true
    },
    inputSetId: {
      type: String,
      required: true
    },
    inputs: {
      type: Array,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    link: {
      type: Object,
      default: null
    },
    errorMessage: {
      type: String,
      default: ''
    },
    successMessage: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean
    }
  },

  data () {
    return {
      areInputsValid: true,
      validate: false
    }
  },

  methods: {
    async validateInputs () {
      this.validate = true
      await this.$nextTick()
      return this.areInputsValid
    },

    async submit () {
      if (!(await this.validateInputs())) {
        return
      }
      this.onSubmit()
    }
  }
}
</script>
