var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label-cols":_vm.showLabels ? _vm.labelSize : 0,"label-for":_vm.id}},[(_vm.showLabels)?_c('template',{slot:"label"},[_c('span',{class:{ 'property-required': _vm.required }},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_c('b-input-group',{staticClass:"d-flex flex-nowrap"},[_c('b-form-input',{ref:_vm.id,attrs:{"id":_vm.id,"placeholder":_vm.internalPlaceholder,"disabled":_vm.disabled,"state":_vm.isValidPassword ? null : _vm.isValidPassword,"type":"password","autofocus":_vm.autofocus},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.allowSubmit && _vm.onSubmit()},"focus":function($event){_vm.passwordWasFocused = true},"blur":function($event){_vm.passwordWasBlurred = true}},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1)],2),(_vm.confirmPassword)?[_c('transition',{attrs:{"name":"fade"}},[(_vm.passwordWasFocused && !_vm.allRuleFulfilled)?_c('div',[_c('ul',{staticClass:"mb-0 pb-3"},[_c('transition-group',{attrs:{"name":"fade"}},[_vm._l((_vm.ruleStatuses),function(rule){return [(!rule.status)?_c('li',{key:rule.message,class:{
                  'text-danger': _vm.passwordWasBlurred && !rule.status
                }},[_vm._v(" "+_vm._s(_vm.$t(rule.message))+" ")]):_vm._e()]})],2)],1)]):_vm._e()]),_c('b-form-group',{attrs:{"label-cols":_vm.showLabels ? _vm.labelSize : 0,"label-for":_vm.confirmId}},[(_vm.showLabels)?_c('template',{slot:"label"},[_c('span',{class:{ 'property-required': _vm.required }},[_vm._v(_vm._s(_vm.confirmPasswordLabel))])]):_vm._e(),_c('b-input-group',{staticClass:"d-flex flex-nowrap"},[_c('b-form-input',{ref:_vm.confirmId,attrs:{"id":_vm.confirmId,"placeholder":_vm.internalConfirmPlaceholder,"disabled":_vm.disabled,"state":_vm.isValidConfirmPassword ? null : _vm.isValidConfirmPassword,"type":"password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.allowSubmit && _vm.onSubmit()},"blur":function () {
            if (_vm.value) {
              _vm.confirmPasswordWasBlurred = true
            }
          }},model:{value:(_vm.confirmValue),callback:function ($$v) {_vm.confirmValue=$$v},expression:"confirmValue"}})],1)],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.value !== '' && _vm.confirmValue !== '' && _vm.confirmValue !== _vm.value)?_c('div',{class:{
          'text-danger': _vm.confirmPasswordWasBlurred
        }},[_c('ul',{staticClass:"mb-0 pb-3"},[_c('li',[_vm._v(" "+_vm._s(_vm.$t('login.passwordsMustMatch'))+" ")])])]):_vm._e()])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }